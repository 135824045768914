import React from "react"
import { css } from "@emotion/core"

import Head from "../components/head"
import Layout from "../components/layout/"
import Markdown from "../components/Markdown"
import Img from '../components/Img'

import home from "../cms/home"

export default () => {
  return (
    <Layout>
      <Head />

        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 2rem;
            grid-row-gap: 3rem;

            @media (max-width: 40rem) {
              grid-template-columns: 1fr;
              grid-row-gap: 1rem;

              --image-border-radius: 4px;
            }

            --image-border-radius: 8px;
          `}
        >
          <Img
            src={home.banner_image}
            alt=""
            css={css`
              grid-column: 1 / -1;
              border-radius: var(--image-border-radius);
            `}
            width={1000}
          />

          <Markdown
            raw={home.text}
            css={css`
              font-size: 20px;
            `}
          />

          <div>
            <Img
              src={home.image}
              alt=""
              css={css`
                border-radius: var(--image-border-radius);
              `}
              width={600}
            />

            <Markdown
              css={css`
                color: var(--text-light-gray);
                margin-top: .5rem;
                font-size: 16px;
              `}
              raw={home.image_credit}
            />
          </div>
      </div>


    </Layout>
  )
}
