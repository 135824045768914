import React from "react"
import { css } from "@emotion/core"
import snarkdown from "snarkdown"

export default ({ className, raw="" }) => {
  return (
    <div
      css={css`
        font-size: 18px;
        line-height: 1.5;

        * {
          font-size: inherit;
          color: inherit;
          margin-bottom: 1.5em;
        }

        br {
          content: "";
          display: block;
          margin-bottom: 1.5em;
        }

        h1 {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 16px;
          margin-top: 32px;
          color: var(--text-black);
        }

        ul, ol {
          margin-left: 2em;
        }

        a {
            text-decoration: underline;
        }
      `}
      className={className}
      dangerouslySetInnerHTML={{ __html: snarkdown(raw) }}
    />
  )
}
